import Image from 'next/image'

const Loader = () => {
    return (
        <div className={'flex justify-center'}>
            <Image src={'/pizza.gif'} alt="my gif" height={200} width={200} />
        </div>
    )
}
export default Loader
