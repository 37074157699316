import Loader from '@/ui/components/shared/Loader'
import Button from '@/ui/components/shared/Button'
import Link from 'next/link'
import { useEffect } from 'react'
import { captureException } from '@sentry/nextjs'
import { useRouter } from 'next/router'

const Custom404 = () => {
    let router = useRouter()

    useEffect(() => {
        try {
            throw new Error('Wrong url: ' + router.asPath)
        } catch (e) {
            captureException(e)
        }
    }, [])
    return (
        <div className={'h-screen flex flex-col justify-around justify-center bg-turbo-background content-between'}>
            <div className={'container mx-auto '}>
                <div className={'font-fk_screamer text-3xl md:text-7xl md:text-9xl justify-center flex pb-8'}>
                    Stránka nenalezena
                </div>
                <div
                    className={
                        'font-fk_screamer text-3xl md:text-7xl md:text-9xl justify-center flex flex-col md:flex-row items-center'
                    }
                >
                    <p>Tak tady</p>
                    <Loader />
                    <p>nebude</p>
                </div>
                <Link href={'/'}>
                    <div className={'flex items-center justify-center mt-4'}>
                        <Button bgColor={'background-secondary'} variant={'solid'} title={'Chci zpátky'} />
                    </div>
                </Link>
            </div>
        </div>
    )
}
export default Custom404
